import styles from "././MoiHeader.styles.scss";
import moiLogoEn from "./moi-logo-en.png";
import moiLogoFr from "./moi-logo-fr.png";
import {getUserLang} from "./utils";

const logo = {
  en: moiLogoEn,
  fr: moiLogoFr,
}

function MoiHeader() {
  const lang = getUserLang()

  return (
    <header className={styles.header}>
      <img src={logo[lang]} alt="" />
    </header>
  );
}

export default MoiHeader;
