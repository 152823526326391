import {h} from 'preact'
import styles from './MoiProgramErrorPage.styles.scss'
import {ErrorPageComponent} from "../../../StandAloneErrorPage";
import fr1 from './fr-1.png'
import fr2 from './fr-2.png'
import fr3 from './fr-3.png'
import fr4 from './fr-4.png'
import surprise from './surprise.png'
import MoiHeader from "./MoiHeader";
import {getUserLang} from "./utils";
import useTranslation from "../../../../../hooks/useTranslation";

const MoiProgramErrorPage: ErrorPageComponent = () => {
  const lang = getUserLang()
  const {t} = useTranslation()

  return (
      <div className={styles.pageWrapper}>
        <div class={styles.overlay}>
          <div className={styles.overlayInner}>
            <img src={surprise} style={{height: 200}} alt="" />
            <div
              className={styles.innerTitle}
              dangerouslySetInnerHTML={{__html: t('moiProgram.reconnection.exclusiveOffers')}}
            />
            <p>{t('moiProgram.reconnection.signin')}</p>
            <a
              href={`https://www.programmemoi.ca/${lang}`}
              className={styles.moiBtn}
            >
              {t('moiProgram.reconnection.signinBtn')}
            </a>
            <a href={`https://www.programmemoi.ca/${lang}`}>{t('moiProgram.reconnection.backLink')}</a>
          </div>
        </div>
        <MoiHeader />
        <div class="container">
          <h1 className={styles.title}>{t('moiProgram.title')}</h1>
          <div class="row">
            <div class="col-3">
              <img src={fr1} alt="" />
            </div>
            <div class="col-3"><img src={fr2} alt="" /></div>
            <div class="col-3"><img src={fr3} alt="" /></div>
            <div class="col-3"><img src={fr4} alt="" /></div>
          </div>
        </div>
      </div>
  )
}

export default MoiProgramErrorPage
